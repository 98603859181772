<template>
	<span class="mr-2 ml-3">
		<v-btn icon small :disabled="!canGoBack" @click="goBack">
			<v-icon>mdi-chevron-left</v-icon>
		</v-btn>
		<v-btn icon small :disabled="!canGoForward" @click="goForward">
			<v-icon>mdi-chevron-right</v-icon>
		</v-btn>
	</span>
</template>

<script>
	export default {
		name: "NavigateSection",
		computed: {
			conference() {
				return this.$store.state.conference.data;
			},
			currentSection() {
				return this.$store.state.current.section;
			},
			currentResponse() {
				return this.$store.state.current.response;
			},
			currentQuestion() {
				return this.conference.question;
			},
			questions() {
				return this.mwsurveyutils.currentVisibleQuestions();
			},
			firstQuestion() {
				return this.questions[0];
			},
			currentIndex() {
				return this.questions.indexOf(this.currentQuestion);
			},
			canGoBack() {
				return this.currentIndex !== 0;
			},
			canGoForward() {
				return this.questions.length > this.currentIndex + 1;
			},
			section() {
				return this.$store.state.sections.data[this.currentSection];
			},
			response() {
				return this.$store.state.sectionResponses.data[this.currentResponse];
			},
			service() {
				if (!this.response.service) {
					return null;
				}
				return this.$store.getters["categoryOptions/getName"](
					this.response.service
				);
			},
			title() {
				let title = this.section.name;
				if (this.service) {
					title += " - ";
					title += this.service;
				}
				return title;
			}
		},
		watch: {
			firstQuestion: {
				immediate: true,
				handler(v) {
					if (v) {
						this.$store.dispatch("conference/patch", {
							question: v,
							title: this.title
						});
					}
				}
			},
			currentQuestion(v) {
				if (v) {
					let pos = document.getElementById(v);
					pos = pos.offsetTop;
					if (pos) {
						window.scrollTo({
							top: pos,
							left: 0,
							behavior: "smooth"
						});
					}
				}
			}
		},
		methods: {
			goBack() {
				this.$store.dispatch("conference/patch", {
					question: this.questions[this.currentIndex - 1]
				});
			},
			goForward() {
				this.$store.dispatch("conference/patch", {
					question: this.questions[this.currentIndex + 1]
				});
			}
		}
	};
</script>
