<template>
	<Bar
		:chart-options="combinedOptions"
		:chart-data="chartData"
		:chart-id="chartId"
		:dataset-id-key="datasetIdKey"
		:plugins="plugins"
		:css-classes="cssClasses"
		:styles="styles"
		:width="width"
		:height="height"
	/>
</template>

<script>
	import {Bar} from "vue-chartjs";

	import {
		Chart as ChartJS,
		Title,
		Tooltip,
		Legend,
		BarElement,
		CategoryScale,
		LinearScale
	} from "chart.js";

	ChartJS.register(
		Title,
		Tooltip,
		Legend,
		BarElement,
		CategoryScale,
		LinearScale,
	);

	export default {
		name: "BarChart",
		components: {
			Bar
		},
		props: {
			chartData: Object,
			chartOptions: Object,
			chartId: {
				type: String,
				default: "bar-chart"
			},
			datasetIdKey: {
				type: String,
				default: "label"
			},
			width: {
				type: Number,
				default: 400
			},
			height: {
				type: Number,
				default: 400
			},
			cssClasses: {
				default: "",
				type: String
			},
			styles: {
				type: Object,
				default: () => {
					return {
						height: "450px"
					}
				}
			},
			plugins: {
				type: Object,
				default: () => {}
			}
		},
		data() {
			return {
				options: {
					responsive: true,
					indexAxis: "y",
					maintainAspectRatio: false
				}
			};
		},
		computed: {
			combinedOptions() {
				return {...this.options, ...this.chartOptions};
			}
		}
	};
</script>
