<template>
	<mw-dialog
		v-if="isLeadership"
		v-model="dialog"
		submitBtn
		cancelBtn
		@submit="submit"
		title="Submit For Approval"
	>
		<template v-slot:button>
			<v-tooltip top>
				<template v-slot:activator="{on}">
					<v-btn
						v-on="on"
						color="green"
						outlined
						icon
						@click="dialog = true"
						:disabled="disable"
					>
						<v-icon>mdi-check</v-icon>
					</v-btn>
				</template>
				Submit Everything
			</v-tooltip>
		</template>
		<template v-slot:dialog-body>
			<p v-if="disable">
				In order to submit this assessment for approval, all sections must be
				undelegated and the assessment must have a compeletion rate of over 80%
			</p>
			<render-content id="submitall"></render-content>
		</template>
	</mw-dialog>
</template>

<style lang="less">
	.button {
		cursor: pointer;
	}
</style>
<script>
	import MwDialog from "@c/ui/MwDialog";
	import RenderContent from "@c/ui/RenderContent";

	export default {
		name: "CompleteAll",
		data: () => {
			return {
				dialog: false,
				color: "green"
			};
		},
		computed: {
			isLeadership(){
				return this.$store.getters.isLeadership
			},
			disable() {
				return this.cantComplete.length > 0;
			},
			responses() {
				return this.$store.state.sectionResponses.data;
			},
			order() {
				return this.$store.getters.order;
			},
			completion() {
				return this.$store.getters.completionRate;
			},
			cantComplete() {
				const self = this;
				if (!self.responses) {
					return;
				}
				let responses = self.order.map((o) => {
					return self.responses[o.response];
				});
				return responses
					.filter((r) => r)
					.filter((r) => {
						return ["delegated"].includes(r.status);
					});
			},
			canComplete() {
				const self = this;
				if (!self.responses) {
					return;
				}
				let responses = self.order.map((o) => {
					return self.responses[o.response];
				});
				return responses
					.filter((r) => r)
					.filter((r) => {
						return [
							"not_started",
							"in_progress",
							"returned",
							"revoked"
						].includes(r.status);
					})
					.map((r) => r.id);
			}
		},
		components: {
			MwDialog,
			RenderContent
		},
		methods: {
			submit() {
				const self = this;
				self.dialog = false;
				self.canComplete.forEach((id) => {
					self.$store
						.dispatch("sectionResponses/patch", {
							id: id,
							status: "complete"
						})
						.then(() => {
							self.dialog = false;
						});
				});
				self.$store.dispatch("assessment/patch", {stage: "submitted"}).then ( () => {
					self.$root.$emit("showToast", "Assessment Submitted for Approval")
				}) 
			}
		}
	};
</script>
