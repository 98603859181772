<template>
	<div>
		<template v-if="mini">
			<v-avatar size="38" color="primary" class="ml-2 mb-1 white--text">
				<small v-if="assessmentIsOpen"> {{ completion }}% </small>
				<v-icon color="white" v-else>
					mdi-check
				</v-icon>
			</v-avatar>
		</template>
		<template v-else>
			<template v-if="assessmentIsOpen">
				<completion-rate :value="completion" :height="20"></completion-rate>
			</template>
			<template v-else>
				<v-list-item>
					<v-list-item-icon>
						<v-icon color="success">{{ icon }}</v-icon>
					</v-list-item-icon>
					<v-list-item-title>
						Assessment {{ assessment.stage }}
					</v-list-item-title>
				</v-list-item>
			</template>
		</template>
	</div>
</template>

<script>
	import CompletionRate from "@c/navigation/assessment/CompletionRate";

	export default {
		name: "AssessmentNavigationHeader",
		props: {
			mini: Boolean
		},
		computed: {
			assessment() {
				return this.$store.state.assessment.data;
			},
			assessmentIsOpen() {
				return this.assessment.stage == "in_progress";
			},
			completion() {
				return this.$store.getters.completionRate;
			},
			icon() {
				return "mdi-check-circle";
			}
		},
		components: {
			CompletionRate
		}
	};
</script>
