<template>
	<v-navigation-drawer
		v-model="drawer"
		:mini-variant="mini"
		permanent
		app
		right
		style="z-index: 101"
		touchless
		width="350px"
	>
		<v-list dense>
			<assessment-navigation-header :mini="mini"></assessment-navigation-header>

			<v-divider class="mt-1 mb-2"></v-divider>
			<template v-if="!mini">
				<section-navigation
					v-for="id in mySections"
					:id="id"
					:key="id"
				></section-navigation>
			</template>
		</v-list>
		<template v-slot:append>
			<v-divider class="mt-1 mb-3"></v-divider>
			<assessment-buttons
				:mini="mini"
				@save="$emit('save')"
			></assessment-buttons>
			<v-list dense class="pl-1">
				<nav-item
					@click="mini = !mini"
					:text="$t('buttons.collapse')"
					icon="mdi-menu"
				></nav-item>
			</v-list>
		</template>
	</v-navigation-drawer>
</template>
<style lang="less">
	.v-list-item {
		padding: 0px 10px !important;
	}
</style>
<script>
	import NavItem from "@c/navigation/Item";
	import SectionNavigation from "@c/navigation/assessment/sections/SectionNavigation";
	import AssessmentButtons from "@c/navigation/assessment/Buttons";
	import AssessmentNavigationHeader from "@c/navigation/assessment/AssessmentNavigationHeader.vue";
	export default {
		name: "AssessmentNavigation",
		data() {
			return {
				drawer: true,
				mini: false
			};
		},
		computed: {
			mySections() {
				return this.$store.getters.mySections;
			},
			isDelegate() {
				return this.$store.getters.isDelegate;
			},
			assessment() {
				return this.$store.state.assessment.data;
			},
			responses() {
				return this.$store.state.sectionResponses.data;
			},
		},
		components: {
			AssessmentNavigationHeader,
			NavItem,
			SectionNavigation,
			AssessmentButtons
		},
		inject: ["isMobile"],
		created() {
			if (this.isMobile) {
				this.mini = true;
			}
		}
	};
</script>
