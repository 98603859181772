<template>
	<mw-dialog v-model="dialog" title="Results" cancel-btn>
		<template v-slot:button>
			<v-tooltip top>
				<template v-slot:activator="{on}">
					<v-badge
						color="success"
						class="white--text"
						:content="counter || '0'"
						overlap
					>
						<v-icon class="mr-3" v-on="on" @click="dialog = true"
							>mdi-chart-bar</v-icon
						>
					</v-badge>
				</template>
				Responses to the current question
			</v-tooltip>
		</template>
		<template v-slot:dialog-body>
			<div>
				<p v-if="questionTitle">{{ questionTitle.string }}</p>
				<bar-chart
					v-if="answerOptions"
					:chart-options="chartOptions"
					:chart-data="chartData"
				></bar-chart>
				<template v-else>
					<p v-for="(result, i) in results" :key="i">
						{{ result }}
					</p>
				</template>
			</div>
		</template>
		<template v-slot:dialog-buttons>
			<export-button
				icon
				:rows="[countResults]"
				:columns="answerOptions"
			></export-button>
		</template>
	</mw-dialog>
</template>

<script>
	import _ from "lodash";
	import BarChart from "@/assets/charts/BarChart.vue";
	import MwDialog from "@c/ui/MwDialog.vue";
	import ExportButton from "@c/downloads/Export";
	// import TestBar from "./TestBar.vue";
	export default {
		name: "ConferenceResults",
		data: () => {
			return {
				dialog: false
			};
		},
		computed: {
			current() {
				return this.$store.state.current;
			},

			conference() {
				return this.$store.state.conference.data;
			},
			questionTitle() {
				return this.$store.getters["questions/getQuestionTexts"](
					this.conference.question
				);
			},
			answerOptions() {
				let ao = this.$store.getters["questions/getAnswerOptions"](
					this.conference.question
				);
				if (!ao) {
					return null;
				}
				return _.sortBy(Object.values(ao), "position");
			},
			labels() {
				return this.answerOptions.map((a) => a.text);
			},
			results() {
				return _.get(this.conference, `data.${this.conference.question}`, []);
			},
			countResults() {
				return _.countBy(this.results);
			},
			counter() {
				return this.results.length;
			},
			chartData() {
				const self = this;
				let data = self.answerOptions.map(
					(l) => self.countResults[l.value] || 0
				);
				return {
					labels: self.labels,
					datasets: [{data, label: "Responses", backgroundColor: "#193758"}]
				};
			},
			chartOptions() {
				let options = {};
				_.set(options, "scales.x.ticks.stepSize", 1);
				return options;
			}
		},
		components: {
			MwDialog,
			BarChart,
			ExportButton
			// TestBar
		}
	};
</script>
