<template>
	<mw-dialog v-model="dialog" title="Create Poll" large>
		<template v-slot:dialog-body>
			<v-sheet flat>
				<p>
					Create a poll to send one or more questions to your colleagues
					and see how they would answer. You'll see a count of answers
					shown in green underneath each answer option in the
					questionnaire; and individual poll results received are shown
					here.
				</p>
				<v-stepper elevation="0" v-model="step">
					<collaboration-wizard-header
						text="Questions"
						:value="step"
					></collaboration-wizard-header>
					<v-stepper-content step="1">
						<v-card elevation="0">
							<v-checkbox
								hide-details
								v-for="question in questionTexts"
								v-model="selected"
								multiple
								:key="question.id"
								:value="question.id"
								:label="question.text"
							>
							</v-checkbox>
							<v-card-actions class="mt-8 pb-0">
								<v-btn text @click="dialog = false">Cancel</v-btn>
								<v-spacer></v-spacer>
								<v-btn
									color="primary"
									@click="step = 2"
									:disabled="selected.length == 0"
								>
									Continue
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-stepper-content>

					<v-stepper-content step="2">
						<v-card elevation="0">
							<p>To create your poll, please either select a user below or create a new user by typing their name.</p>
							<div class="mb-8">
								<v-checkbox
									hide-details
									v-for="colleague in colleagues"
									v-model="colleagues"
									multiple
									:key="colleague"
									:value="colleague"
									:label="users[colleague] ? users[colleague].name : ''"
								>
								</v-checkbox>
							</div>
							<add-colleague
								@input="addColleague"
								clear-on-emit
								:hide="colleagues"
							></add-colleague>
							<v-card-actions class="mt-8 mb-0">
								<v-btn text @click="dialog = false">Cancel</v-btn>
								<v-spacer></v-spacer>
								<v-btn
									color="primary"
									@click="step = 3"
									:disabled="colleagues.length == 0"
								>
									Continue
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-stepper-content>
					<v-stepper-content step="3">
						<v-card elevation="0">
							<p>
								Adding polls for {{ selected.length }} questions for
								{{ colleagues.length }} colleagues.
							</p>
							<p>Please confirm by clicking on 'complete' to create your poll.</p>
							<v-checkbox
								v-model="notify"
								label="Notify participants?"
							></v-checkbox>
							<v-card-actions class="mt-8 mb-0">
								<v-btn text @click="dialog = false">Cancel</v-btn>
								<v-spacer></v-spacer>
								<v-btn color="primary" @click="complete">
									Complete
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-stepper-content>
				</v-stepper>
			</v-sheet>
		</template>
	</mw-dialog>
</template>
<style scoped>
	.v-input--selection-controls {
		margin-top: 8px;
	}
	.v-stepper__content {
		padding-top: 0px;
	}
</style>
<script>
	import MwDialog from "@c/ui/MwDialog";
	import CollaborationWizardHeader from "@c/collaboration/CollaborationWizardHeader.vue";

	import AddColleague from "@c/collaboration/AddColleague.vue";
	export default {
		name: "NewPoll",
		data: () => {
			return {
				dialog: null,
				step: 1,
				colleagues: [],
				selected: [],
				notify: true
			};
		},
		components: {
			AddColleague,
			MwDialog,
			CollaborationWizardHeader
		},
		computed: {
			current() {
				return this.$store.state.current;
			},
			section() {
				return this.$store.state.sections.data[this.current.section];
			},
			response() {
				return this.$store.state.sectionResponses.data[this.current.response];
			},
			visibleQuestions() {
				return this.mwsurveyutils.currentVisibleQuestions();
			},
			questionTexts() {
				const self = this;
				let questions =  self.visibleQuestions
				questions = questions.filter( q => !self.questions[q].hide_polling );
				questions =  questions.map((id) => {
					let text = self.$store.getters["questions/getQuestionTexts"](id);
					return {id, text: text.string};
				});
				return questions;
			},
			users() {
				return this.$store.state.users.data;
			},
			organisation() {
				return this.$store.state.organisation.data;
			},
			questions() {
				return this.$store.state.questions.data;
			}
		},
		methods: {
			addColleague(id) {
				this.colleagues.push(id);
			},
			clear() {
				this.selected = [];
				this.colleagues = [];
				this.step = 1;
			},
			complete() {
				const self = this;
				const notify = self.notify;
				self.colleagues.forEach((user) => {
					self.$store.dispatch("polls/manyNew", {
						questions: self.selected,
						user,
						notify
					});
				});
				self.dialog = false;
			}
		},
		watch: {
			dialog(v) {
				if (!v) {
					this.clear();
				}
			}
		},
		mounted() {
			const self = this;
			self.$root.$on("newPoll", (id) => {
				self.selected.push(id);
				self.dialog = true;
			});
		}
	};
</script>
