<template>
	<mw-dialog
		v-model="dialog"
		submit-btn
		cancel-btn
		@submit="dialog = false"
		:title="$t('assessment.upload_evidence')"
	>
		<template v-slot:button v-if="!error">
			<v-tooltip top>
				<template v-slot:activator="{on}">
					<v-btn v-on="on" icon @click="dialog = true" outlined class="mr-2">
						<v-icon>mdi-upload</v-icon>
					</v-btn>
				</template>
				Evidence and Upload
			</v-tooltip>
		</template>
		<template v-slot:dialog-body>
			<div v-html="$t('assessment.before_upload_evidence')"></div>
			<v-autocomplete
				v-model="section"
				:items="sectionItems"
				label="Section"
				clearable
			></v-autocomplete>
			<v-autocomplete
				clearable
				v-model="question"
				:items="questionItems"
				label="Question"
				v-if="section"
			></v-autocomplete>
			<v-textarea label="Description" v-model="description"></v-textarea>
			<upload-field @uploaded="fileUploaded" :location="folder"></upload-field>
			<div v-html="$t('assessment.before_evidence_list')"></div>
			<v-list dense>
				<file-list-item
					v-for="(file, i) in currentFiles"
					:key="i"
					v-bind="file"
					@delete="deleteFile(file)"
				>
				</file-list-item>
			</v-list>
		</template>
	</mw-dialog>
</template>

<style lang="less">
	.button {
		cursor: pointer;
	}
</style>
<script>
	import MwDialog from "@c/ui/MwDialog";
	import UploadField from "@c/evidence-upload/UploadField.vue";
	import FileListItem from "@c/evidence-upload/FileListItem.vue";
	import {
		updateMetaData,
		listFiles,
		getMetaData
	} from "@/assets/storageFunctions.js";

	export default {
		name: "UploadEvidence",
		props: {
			id: {type: String}, // the ID of the response,
			icon: {type: Boolean, default: false}
		},
		data: () => {
			return {
				description: "",
				error: false,
				dialog: false,
				section: false,
				question: false,
				files: []
			};
		},
		computed: {
			folder() {
				let org = this.$store.getters["organisation/id"];
				return `/organisations/${org}`;
			},
			current() {
				return this.$store.state.current;
			},
			sectionItems() {
				const self = this;
				var mySections = this.$store.getters.mySections;
				var sections = mySections
					.map((s) => self.$store.state.sections.data[s])
					.filter((a) => a);
				return sections.map((a) => {
					return {
						text: a.name,
						value: a.id
					};
				});
			},
			sectionObject() {
				if (this.section) {
					return this.$store.state.sections.data[this.section];
				}
				return {};
			},
			questionTemplates() {
				return this.$store.state.questionTemplates.data;
			},
			questions() {
				if (!this.sectionObject) {
					return false;
				}
				if (!this.sectionObject.questions) {
					return [];
				}
				return this.sectionObject.questions.map((q) => {
					return this.$store.state.questions.data[q];
				});
			},
			questionItems() {
				const self = this;
				return self.questions.map((question) => {
					let texts = self.$store.getters["questions/getQuestionTexts"](
						question.id
					);
					return {
						value: question.id,
						text: texts.string
					};
				});
			},
			currentFiles() {
				const self = this;
				let files = [...self.files];
				let mySections = this.$store.getters.mySections;
				files = files.map((f) => {
					if (f.section == "false") {
						f.section = false;
					}
					if (f.question == "false") {
						f.question = false;
					}
					return f;
				});
				files = files.filter(
					(a) => !a.section || mySections.includes(a.section)
				);
				if (self.section) {
					files = files.filter((file) => file.section == self.section);
				}
				if (self.question) {
					files = files.filter((file) => file.question == self.question);
				}
				return files;
			}
		},
		watch: {
			current: {
				immediate: true,
				deep: true,
				handler(value) {
					this.section = value.section;
				}
			}
		},
		components: {
			UploadField,
			MwDialog,
			FileListItem
		},
		methods: {
			clear() {
				this.description = null;
			},
			deleteFile(file) {
				let index = this.files.findIndex((f) => file.path == f.path);
				return this.files.splice(index, 1);
			},
			fileUploaded(file) {
				let meta = {
					created_by: this.$store.getters["auth/id"],
					assessment: this.$store.getters["assessment/id"],
					section: this.section,
					description: this.description,
					question: this.question
				};
				updateMetaData(meta, file.ref.fullPath);

				this.files.push({
					...meta,
					name: file.ref.name,
					path: file.ref.fullPath
				});

				this.clear();
			}
		},
		created() {
			const self = this;
			if (!self.folder) {
				return;
			}
			listFiles("/" + self.folder)
				.then((a) => {
					a.items.forEach((item) => {
						getMetaData(item.fullPath).then((meta) => {
							let file = meta.customMetadata;
							file.name = item.name;
							file.path = item.fullPath;
							self.files.push(file);
						});
					});
				})
				.catch(() => {
					self.error = true;
				});
		}
	};
</script>
