<template>
	<mw-dialog v-model="dialog" cancel-btn :title="$t('assessment.filter_sections')">
		<template v-slot:button>
			<v-tooltip top>
				<template v-slot:activator="{ on }">
					<v-btn v-on="on" icon outlined class="mr-2" @click="dialog = true" :color="color">
						<v-icon>mdi-filter</v-icon>
						<v-badge v-if="count" x-small color="red" :content="count"> </v-badge>
					</v-btn>
				</template>
				Filter Assessment Content
			</v-tooltip>
		</template>
		<template v-slot:dialog-body>
			<div v-html="$t('assessment.before_filter')"></div>
			<v-select chips multiple clearable :items="themes" item-value="id" item-text="name" v-model="selectedThemes" label="Theme"></v-select>
			<v-select chips clearable label="Status" multiple v-model="selectedStatuses" :items="statuses"></v-select>
			<v-checkbox label="Hide answered questions" v-model="hide_answered"></v-checkbox>
		</template>
		<template v-slot:dialog-buttons>
			<v-btn color="error" text @click="clearFilters" v-if="filter.length">Clear Filter</v-btn>
			<v-btn color="primary" @click="submit">Submit</v-btn>
		</template>
	</mw-dialog>
</template>

<script>
import MwDialog from "@c/ui/MwDialog";
export default {
	name: "FilterButton",
	data: () => {
		return {
			dialog: false,
			selectedThemes: [],
			selectedStatuses: [],
			hide_answered: false,
			statuses: [
				{ text: "In Progress", value: "in_progress" },
				{ text: "Returned", value: "returned" },
				{ text: "Complete", value: "complete" },
				{ text: "Revoked", value: "revoked" },
				{ text: "Not Started", value: "not_started" },
				{ text: "Delegated", value: "delegated" },
			],
		};
	},
	computed: {
		mySections() {
			return this.$store.getters.mySections;
		},
		responses() {
			return this.$store.state.sectionResponses.data;
		},
		sections() {
			return this.$store.state.sections.data;
		},
		hideAnswered() {
			return this.$store.state.hide_answered;
		},
		themes() {
			return this.$store.getters["themes/options"];
		},
		filter() {
			return this.$store.state.sections.filter;
		},
		color() {
			return this.filter.length || this.hide_answered ? "green" : "";
		},
		count() {
			return this.filter.length || this.hide_answered ? 1 : 0;
		},
	},
	methods: {
		clearFilters(){
			this.clear();
			this.dialog = false;
		},
		clear() {
			this.$store.commit("sections/CLEAR_FILTER");
		},
		filterByTheme(responses) {
			const self = this;
			if (!self.selectedThemes.length) {
				return responses;
			}
			return responses.filter((id) => {
				let response = self.responses[id];
				let section = self.sections[response.section];
				if( !section ){
					return false;
				}
				return self.selectedThemes.includes(section.theme);
			});
		},
		filterByStatus(responses) {
			const self = this;
			if (!self.selectedStatuses.length) {
				return responses;
			}
			return responses.filter((id) => {
				let response = self.responses[id];
				return self.selectedStatuses.includes(response.status);
			});
		},
		submit() {
			const self = this;
			let filter = [];
			if (self.selectedThemes.length == 0 && self.selectedStatuses.length == 0) {
				self.clear();
			} else {
				let responses = Object.keys(self.responses);
				responses = self.filterByTheme(responses);
				responses = self.filterByStatus(responses);
				filter = responses.map((r) => {
					return self.responses[r].section;
				});
				filter = this.mwutils.uniqueArray(filter);
				self.$store.commit("sections/FILTER", filter);
			}
			self.$store.commit("HIDE_ANSWERED", this.hide_answered);
			self.dialog = false;
		},
	},
	watch: {
		hideAnswered: {
			immediate: true,
			handler(value) {
				if (value !== this.hide_answered) {
					this.hide_answered = value;
				}
			},
		},
	},
	components: {
		MwDialog,
	},
};
</script>
