<template>
	<v-sheet flat>
		<conference-toolbar></conference-toolbar>
		<loading v-if="loading"></loading>
		<div v-else>
			<div id="assessmentWrapper" v-if="haveSections">
				<template v-if="current.response">
					<survey-section @save="save(false)"></survey-section>
				</template>
				<div v-else>
					<render-content id="assessmentwelcome"></render-content>
					<p class="text-right"></p>
					<v-btn @click="start" color="primary" class="mt-3" dark large>{{
						$t("assessment.start")
					}}</v-btn>
					<assessment-stats></assessment-stats>
				</div>
				<navigation @save="save(true)"></navigation>
				<v-snackbar color="primary" v-model="saved" text>{{
					$t("misc.saved")
				}}</v-snackbar>
			</div>

			<no-sections v-else></no-sections>
		</div>
	</v-sheet>
</template>

<style lang="less">
	.assessmentWrapper {
		position: relative;
	}
</style>

<script>
	import ConferenceToolbar from "@c/conference/Toolbar";
	import Loading from "@c/ui/Loading";
	import SurveySection from "@c/survey/render/Section";
	import Navigation from "@c/navigation/Assessment";
	import NoSections from "@c/notices/NoSections";
	import RenderContent from "@c/ui/RenderContent.vue";
	import AssessmentStats from "@c/survey/tools/AssessmentStats.vue";

	export default {
		name: "Assessment",
		components: {
			AssessmentStats,
			Navigation,
			SurveySection,
			NoSections,
			Loading,
			RenderContent,
			ConferenceToolbar
		},
		data: () => {
			return {
				error: false,
				loading: true,
				saved: false
			};
		},
		computed: {
			haveSections() {
				return this.$store.getters.mySections.length;
			},
			current() {
				return this.$store.state.current;
			},
			response() {
				return this.$store.state.sectionResponses.data[this.current.response];
			},
			section() {
				return this.response
					? this.$store.state.sections.data[this.response.section]
					: false;
			},
			organisation() {
				return this.$store.state.organisation.data;
			},
			assessment() {
				return this.$store.state.assessment.data
					? this.$store.state.assessment.data
					: false;
			},
			order() {
				return this.$store.getters.order;
			}
		},
		methods: {
			start() {
				this.$store.commit("GO_TO", this.order[0]);
			},
			save(notice) {
				const self = this;
				var data = {...self.response.data};

				for (var key in data) {
					if (typeof data[key] == "undefined") {
						delete data[key];
					}
				}
				let update = {
					id: self.current.response,
					data: data,
					completion_rate: self.response.completion_rate
				};
				self.$store.dispatch("sectionResponses/updateData", update).then(() => {
					if (notice) {
						self.saved = true;
					}
				});
			},
			checkServices() {
				if (Object.keys(this.organisation) == 0) {
					return;
				}
				if (
					!this.organisation["service"] ||
					this.organisation["service"].length == 0
				) {
					this.$router.push({
						name: "OrganisationProfile",
						params: {flag: true}
					});
				}
			}
		},
		watch: {
			assessment: {
				immediate: true,
				handler(value) {
					const self = this;
					if (value.id) {
						self.$store
							.dispatch("assessment/fetchData")
							.then(() => (self.loading = false));
					}
					else{
						self.$router.push("/all-assessments")
					}
				}
			},
			response: {
				deep: true,
				handler(newValue, oldValue) {
					if (oldValue && newValue) {
						if (oldValue.status !== "complete" && oldValue && newValue) {
							if (newValue.id !== oldValue.id) {
								// this.save(true);
							} else if (
								newValue.status == "complete" &&
								oldValue.status !== "complete"
							) {
								// this.save(true);
							}
						}
					}
				}
			},
			haveSections: {
				immediate: true,
				handler(value) {
					if (value) {
						this.checkServices();
					}
				}
			}
		},
		created() {},
		mounted() {
			// to do - check rules instead
			this.checkServices();
		}
	};
</script>
