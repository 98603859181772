<template>
	<v-sheet flat>
		<v-card flat class="mt-4 mb-4" color="lightest">
			<v-card-title>{{ section.name }}</v-card-title>
			<v-card-text v-html="section.introduction"> </v-card-text>
		</v-card>
		<section-delegated v-if="isLead && isDelegated"></section-delegated>
		<section-complete v-if="isLead && isComplete"></section-complete>
		<h3 v-if="response.multiple">
			{{ responseName }}
		</h3>
		<v-sheet v-if="visibleQuestions.length">
			<questions
				:disabled="disabled"
				:ids="visibleQuestions"
				v-model="response.data"
				@error="error = true"
				@save="$emit('save')"
			></questions>
			<new-poll></new-poll>
		</v-sheet>
		<p v-else>{{ $t("messages.no_questions_found") }}</p>
		<v-snackbar color="primary" v-model="error" text>{{
			$t("error.section_disabled")
		}}</v-snackbar>
	</v-sheet>
</template>

<script>
	import NewPoll from "@c/collaboration/NewPoll";
	import SectionDelegated from "@c/notices/SectionDelegated.vue";
	import SectionComplete from "@c/notices/SectionComplete.vue";
	import Questions from "@c/survey/render/Questions.vue";
	export default {
		name: "SurveySection",
		data: () => {
			return {
				error: false
			};
		},
		components: {
			Questions,
			SectionDelegated,
			SectionComplete,
			NewPoll
		},
		computed: {
			current() {
				return this.$store.state.current;
			},
			assessment() {
				return this.$store.state.assessment.data;
			},
			organisation() {
				return this.$store.state.organisation.data;
			},
			isLead() {
				return this.$store.getters.isLead;
			},
			isDelegated() {
				return this.response.status == "delegated";
			},
			isComplete() {
				return this.response.status == "complete";
			},
			section() {
				return this.$store.state.sections.data[this.current.section];
			},
			questions() {
				return this.$store.state.questions.data;
			},
			questionTemplates() {
				return this.$store.state.questionTemplates.data;
			},
			categoryOptions() {
				return this.$store.state.categoryOptions.data || {};
			},
			disabled() {
				return !this.$store.getters.canCurrentlyEdit;
			},
			response() {
				return this.$store.state.sectionResponses.data[this.current.response];
			},
			sectionQuestions() {
				return this.section.questions || [];
			},
			responseName() {
				var rule = this.$store.getters.currentMultipleRule;
				if (rule) {
					if (rule.name) {
						return rule.name;
					} else {
						return this.categoryOptions[rule.value].name || "";
					}
				}
				return "";
			},
			canPoll() {
				return this.$store.getters.canPoll;
			},
			visibleQuestions() {
				return this.mwsurveyutils.currentVisibleQuestions();
			},
			answeredQuestions() {
				const self = this;
				let answeredIds = Object.keys(self.response.data) || [];
				answeredIds = answeredIds.filter((id) =>
					self.visibleQuestions.includes(id)
				);
				let answered = answeredIds.filter(
					(a) =>
						typeof self.response.data[a] !== "undefined" &&
						self.response.data[a] && self.response.data[a].length
				);

				return answered.filter((q) => {
					let question = self.questions[q];
					let template = self.questionTemplates[question.question_template];
					return !template || !template.exclude_from_count;
				});
			},
			countedQuestions() {
				const self = this;
				return self.visibleQuestions.filter((q) => {
					let question = self.questions[q];
					let template = self.questionTemplates[question.question_template];
					return !template || !template.exclude_from_count;
				});
			},
			completionRate() {
				return (
					(this.answeredQuestions.length / this.countedQuestions.length) * 100
				);
			},
			score() {
				return this.mwsurveyutils.getSectionScore(this.response);
			}
		},
		methods: {},
		watch: {
			completionRate(value) {
				let data = this.response.data;
				for (var key in data) {
					if (typeof data[key] == "undefined") {
						data[key] = "";
					}
				}
				let update = {
					id: this.current.response,
					completion_rate: value,
					data: data
				};
				this.$store
					.dispatch("sectionResponses/updateData", update)
					.catch(() => this.$root.$emit("showToast", "Error saving"));
			},
			current: {
				deep: true,
				immediate: true,
				handler() {
					if (this.canPoll) {
						this.$store.dispatch("polls/byResponse", this.current.response)
					}
					window.scrollTo(0, 0);

				}
			}
		}
	};
</script>
