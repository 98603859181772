<template>
	<mw-dialog v-model="dialog" cancel-btn title="Conference Settings">
		<template v-slot:button>
			<v-btn
				icon
				small
				@click="dialog = true"
			>
				<v-icon>mdi-cog</v-icon>
			</v-btn>
		</template>
		<template v-slot:dialog-body>
			<p>
				You can grant access to your conference by sharing the information below
				with your conference attendees, or simplify the details by editing them.
				Attendees will lose access to the conference when the conference leader
				logs out.
			</p>
			<v-row class="mt-2 mb-2">
				<v-text-field
					ref="idField"
					label="Conference ID"
					:value="id"
					@input="(v) => (new_id = v)"
					hint="Enter the ID of an earlier conference to retrieve previous responses"
					prepend-icon="mdi-identifier"
					persistent-hint
				>
					<template v-slot:append>
						<v-btn icon color="success" @click="changeId" v-if="new_id"
							><v-icon>mdi-check-circle</v-icon></v-btn
						>
					</template>
				</v-text-field>
				<copy-button :text="id"></copy-button>
			</v-row>
			<v-row class="mt-2 mb-2">
				<v-text-field
					disabled
					label="Conference URL"
					:value="url"
					prepend-icon="mdi-web"
					hide-details
				>
				</v-text-field>
				<copy-button :text="url"></copy-button>
			</v-row>
			<v-row class="mt-2 mb-2">
				<v-text-field
					v-model="conference.password"
					@input="editing_password = true"
					prepend-icon="mdi-lock"
					hide-details
					label="Password"
				>
					<template v-slot:append>
						<v-btn
							icon
							color="success"
							@click="updatePassword"
							v-if="editing_password"
							><v-icon>mdi-check-circle</v-icon></v-btn
						>
					</template>
				</v-text-field>
				<copy-button :text="conference.password"></copy-button>
			</v-row>
		</template>
		<template v-slot:dialog-buttons>
			<v-btn @click="dialog = false" color="primary">Done</v-btn>
		</template>
	</mw-dialog>
</template>
<script>
	import CopyButton from "../buttons/CopyButton.vue";
	import MwDialog from "@c/ui/MwDialog";

	export default {
		props: {
			id: String
		},
		data: () => {
			return {
				dialog: false,
				editing_password: null,
				new_id: null
			};
		},
		computed: {
			conference() {
				return this.$store.state.conference.data;
			},
			url() {
				return `https://conference.dma.works/${this.id}`;
			}
		},
		methods: {
			changeId() {
				this.$store.dispatch("conference/change", this.new_id).then((e) => {
					if (e) {
						this.$root.$emit("showToast", "Retrieved Conference");
					} else {
						this.$root.$emit("showToast", "Permission denied", true);
						this.new_id = this.id;
					}
				});
			},
			updatePassword() {
				this.$store
					.dispatch("conference/patch", {password: this.conference.password})
					.then(() => {
						this.editing_password = false;
						this.$root.$emit("showToast", "Updated Password");
					});
			}
		},
		components: {
			CopyButton,
			MwDialog
		}
	};
</script>
