<template>
	<div class="toolbar-wrapper">
		<v-toolbar floating v-if="show" class="conference-toolbar">
			<v-icon small color="success" class="mr-2">mdi-circle</v-icon>
			<b>Live Conference </b>
			<template v-if="inSection">
				<navigate-section></navigate-section>
				<conference-results></conference-results>
			</template>
			<v-spacer class="ml-2 mr-2"></v-spacer>
			<mw-dialog v-model="helpdialog" title="About Conference Mode" cancel-btn>
				<template v-slot:button>
					<v-btn icon @click="helpdialog = true" small>
						<v-icon>mdi-help-circle</v-icon>
					</v-btn>
				</template>
				<template v-slot:dialog-body>
					<render-content id="conferenceexplainer"></render-content>
				</template>
				<template v-slot:dialog-buttons>
					<v-btn @click="helpdialog = false" color="primary">Done</v-btn>
				</template>
			</mw-dialog>
			<!-- <v-chip class="ml-2" color="secondary" small>{{ conference.id }}</v-chip> -->
			<conference-settings :id="conference.id"></conference-settings>
		</v-toolbar>
	</div>
</template>
<style lang="less">
	.toolbar-wrapper {
		position: fixed;
		bottom: 20px;
		width: 100%;
		z-index: 100;
		.conference-toolbar {
			min-width: 400px;
			position: relative;
			.v-toolbar__content {
				width: 100%;
			}
		}
	}
</style>
<script>
	import ConferenceSettings from "@c/conference/ConferenceSettings.vue";
	import ConferenceResults from "@c/conference/ConferenceResults.vue";
	import NavigateSection from "@c/conference/NavigateSection.vue";
	import MwDialog from "@c/ui/MwDialog.vue";
	import RenderContent from "@c/ui/RenderContent.vue";

	export default {
		name: "ConferenceToolbar",
		data: () => {
			return {
				helpdialog: false
			};
		},
		computed: {
			current() {
				return this.$store.state.current;
			},
			isLead() {
				return this.$store.getters.isLead;
			},
			inSection() {
				return this.current.response ? true : false;
			},
			conference() {
				return this.$store.state.conference.data;
			},
			show() {
				if (!this.isLead) {
					return false;
				}
				if (this.conference.status == "active") {
					return true;
				}
				return false;
			}
		},
		methods: {
			startConference() {
				this.$store.dispatch("conference/start");
			}
		},
		components: {
			ConferenceResults,
			ConferenceSettings,
			NavigateSection,
			MwDialog,
			RenderContent
		}
	};
</script>
