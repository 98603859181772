<template>
	<v-sheet flat class="mt-12" v-if="isLead">
		<h2 class="mb-5">Your Current Assessment Status</h2>
		<v-row>
			<v-col cols="12" lg="4" md="6">
				<number-tile color="lightest"
					:dark="false"
					height="100%"
					text="Sections Completed"
					:number="count('complete')"
				></number-tile>
			</v-col>
			<v-col cols="12" lg="4" md="6">
				<number-tile color="lightest"
					:dark="false"
					height="100%"
					text="Sections Not Started"
					:number="count('not_started')"
				></number-tile>
			</v-col>
			<v-col cols="12" lg="4" md="6">
				<number-tile color="lightest"
					:dark="false"
					height="100%"
					text="Sections Delegated"
					:number="count('delegated')"
				></number-tile>
			</v-col>
			<v-col cols="12" lg="4" md="6">
				<number-tile color="lightest"
					:dark="false"
					height="100%"
					text="In Progress"
					:number="count('in_progress')"
				></number-tile>
			</v-col>
		</v-row>
	</v-sheet>
</template>
<script>
import NumberTile from "@c/ui/NumberTile.vue";

export default {
	name: "AssessmentStats",
	computed: {
		assessment() {
			return this.$store.state.assessment.data;
		},
		order() {
			return this.$store.getters.order;
		},
		sectionResponses() {
			return this.$store.state.sectionResponses.data;
		},
		isLead(){
			return this.$store.getters.isLead;
		},
		responses() {
			const self = this;
			return this.order
				.filter((item) => self.sectionResponses[item.response])
				.map((item) => self.sectionResponses[item.response]);
		},
	},
	methods: {
		count(status) {
			let count = this.responses.filter(
				(response) => response.status == status
			);
			return count.length;
		},
	},
	components: {
		NumberTile,
	},
	created() {
		// this.$store.dispatch("polls/allPolls");
	},
};
</script>
