<template>
	<v-stepper-header class="elevation-0">
		<v-stepper-step :complete="value > 1" step="1">
			Select {{ text }}
		</v-stepper-step>
		<v-divider></v-divider>
		<v-stepper-step :complete="value > 2" step="2">
			Select Colleagues
		</v-stepper-step>
		<v-divider></v-divider>
		<v-stepper-step :complete="value > 3" step="3">
			Review
		</v-stepper-step>
	</v-stepper-header>
</template>
<script>
	export default {
		props: {
			value: {type: Number, default: 1},
			text: {type: String}
		}
	};
</script>
